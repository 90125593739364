import React, { Suspense, useState } from "react";
import { AppLayout } from "../templates";
import { ImageSelectionOption } from "../organisms/main/imageselection/ImageSelection";
import ProductInfo from "../organisms/main/productinfo/ProductInfo";
import { Alert, Spinner } from "flowbite-react";
import { useAlertContext } from "../../context";
const ImagePreview = React.lazy(
  () => import("../organisms/imagepreview/ImagePreview")
);
const FeatureTabs = React.lazy(
  () => import("../organisms/main/MakeupFeature/FeatureTabs")
);

export function Home() {
  const [image, setImage] = useState("");
  const { alertState, setAlertState } = useAlertContext();
  const [isClickImage, setIsClickImage] = useState(false);

  return (
    <AppLayout>
      <div className="container mx-auto mt-4 md:mt-8">
        {alertState.text && (
          <Alert
            color={alertState.status}
            onDismiss={() => setAlertState({ status: "", text: "" })}
          >
            <span>{alertState.text}</span>
          </Alert>
        )}
        {image ? (
          <Suspense
            fallback={
              <div className="flex  justify-center content-center">
                <div className="flex items-center">
                  <Spinner aria-label="Default status example" />
                  <h1 className="ml-2">Loading please wait...</h1>
                </div>
              </div>
            }
          >
            <div className="grid md:grid-cols-1 lg:grid-cols-[40%_50%] gap-4">
              <>
                <ImagePreview
                  image={image}
                  setImage={setImage}
                  setIsClickImage={setIsClickImage}
                />
                <FeatureTabs image={image} />
              </>
            </div>
          </Suspense>
        ) : (
          <div
            className={`flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-around`}
          >
            <ImageSelectionOption
              image={image}
              setImage={setImage}
              isClickImage={isClickImage}
              setIsClickImage={setIsClickImage}
            />
            {!isClickImage && <ProductInfo />}
          </div>
        )}
      </div>
    </AppLayout>
  );
}
