import React from "react";
import useProgressiveImage from "../../../../hooks/useProgressiveImage";
import placeholder from "../../../../images/placeholder.jpeg";

interface IProps {
  data: any;
  onClick: (id: string) => void;
  hairStyleId: string;
}

const HairStyleComponent = (props: IProps) => {
  const loaded = useProgressiveImage(props.data.url);

  return (
    <div
      key={props.data.key}
      className="cursor-pointer overflow-hidden"
      onClick={() => props.onClick(props.data.key)}
    >
      <div
        key={`hair-style-${props.data.key}`}
        className={`border-2 rounded w-32 h-32 overflow-hidden ${
          props.hairStyleId === props.data.key
            ? "border-[#684669]"
            : "border-white"
        } `}
      >
        <img
          className="w-32 h-32 bg-cover object-cover"
          src={loaded || placeholder}
          alt={props.data.name}
        />
      </div>
      <figcaption className="pt-1">{props.data.name}</figcaption>
    </div>
  );
};

export default HairStyleComponent;
