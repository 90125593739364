import { createContext, FunctionComponent, useContext, useState } from "react";

// ---------- AuthContext ----------

type LoadingStateSetter = (isLoadingState: boolean) => void;

/** LoaderContext contains isLoadingState and setLoadingState */

const LoaderContext = createContext<
  { isLoadingState: boolean; setLoadingState: LoadingStateSetter } | undefined
>(undefined);

type LoaderContextProviderProps = {
  children?: React.ReactNode;
};

// ---------- LoaderContextProvider ----------
const LoaderContextProvider: FunctionComponent<LoaderContextProviderProps> = ({
  children,
}) => {
  const [isLoadingState, setLoadingState] = useState<boolean>(false);

  const value = { isLoadingState, setLoadingState };
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

// ---------- useLoaderContext ----------
function useLoaderContext() {
  const loaderContext = useContext(LoaderContext);
  /* istanbul ignore next */
  if (loaderContext === undefined) {
    throw new Error(
      "useLoaderContext must be used within a LoaderContextProvider"
    );
  }
  return loaderContext;
}

export { LoaderContextProvider, useLoaderContext };
