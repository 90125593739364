import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { draw } from "../../utils/utils";
import * as faceApi from "face-api.js";
import { get, size, toNumber } from "lodash";
import { Loader } from "./loader";
import { Spinner } from "flowbite-react";
import Close from "../Svgs/Close";
import Instructions from "./Instructions";
import { Dimensions } from "face-api.js";
import Tick from "../Svgs/Tick";
import * as tmImage from "@teachablemachine/image";
import * as tf from "@tensorflow/tfjs";

const minWidthFace = 150;
const maxWidthFace = 250;
const minOriginX = 170;
const minOriginY = 80;
const maxOriginX = 250;
const maxOriginY = 200;

type WebCamRenderProps = {
  setIsClickImage: (value: boolean) => void;
  webcamRef: React.MutableRefObject<any>;
  handleCapture: () => void;
};

type CameraScanProps = {
  setIsClickImage: (value: boolean) => void;
  handleCapture: () => void;
};

const WebCamRenderer = React.memo(
  ({ setIsClickImage, webcamRef, handleCapture }: WebCamRenderProps) => {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [modalLoading, setModalLoading] = useState(true);
    const [detections, setDetections] = useState({
      x: "",
      y: "",
      width: "",
      height: "",
    });
    const [isFrontFaced, setIsFrontFaced] = useState(false);

    const height = 560;
    const width = 720;

    const getDetections = useCallback(
      async (canvas: any, displaySize: any) => {
        const detections: any = await faceApi
          .detectAllFaces(
            webcamRef.current,
            new faceApi.TinyFaceDetectorOptions({
              scoreThreshold: 0.1,
            })
          )
          .withFaceLandmarks()
          .withFaceExpressions();
        // console.log(
        //   "detection x",
        //   get(detections, "[0].detection._box._x", "")
        // );
        // console.log(
        //   "detection y",
        //   get(detections, "[0].detection._box._y", "")
        // );

        // if (!get(detections, "detection._box._x", "")) alert("face not detected");
        setDetections(() => ({
          x: get(detections, "[0].detection._box._x", ""),
          y: get(detections, "[0].detection._box._y", ""),
          width: get(detections, "[0].detection._box.width", ""),
          height: get(detections, "[0].detection._box.height", ""),
        }));

        const resizedDetections = faceApi.resizeResults(
          detections,
          displaySize
        );
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        // faceApi.draw.drawDetections(canvas, resizedDetections);
        // faceApi.draw.drawFaceLandmarks(canvas, resizedDetections);
        // faceApi.draw.drawFaceExpressions(canvas, resizedDetections);
        setModalLoading(false);
      },
      [webcamRef, setDetections]
    );

    const handlePlay = useCallback(() => {
      // const canvas = faceApi.createCanvasFromMedia(video.srcObject);
      // const canvas: any = faceApi.createCanvas(webcamRef.current);
      const canvas: any = document.createElement("canvas");
      //video.append(canvas);
      canvas.id = "canvas-cam";
      canvas.style.position = "absolute";
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.right = 0;
      canvas.style.bottom = 0;
      canvas.style.maxWidth = "100%";
      canvas.style.marginTop = "-5px";

      const canvasElement: any = document.getElementById(
        "canvas-cam"
      ) as HTMLCanvasElement;
      if (!canvasElement) {
        document.querySelector("#cam-preview")?.append(canvas);
      }
      const displaySize = { width: width, height: height };
      faceApi.matchDimensions(canvas, displaySize);
      setInterval(async () => {
        getDetections(canvas, displaySize);
      }, 1000);
    }, [getDetections]);

    const addEvent = useCallback(() => {
      webcamRef.current.addEventListener("play", handlePlay);
    }, [webcamRef, handlePlay]);

    //
    const URL = "/models/face_position/";

    let model: any, webcam: any, labelContainer: any, maxPredictions: any;

    // Load the image model and setup the webcam
    async function init() {
      const modelURL = URL + "model.json";
      const metadataURL = URL + "metadata.json";

      // load the model and metadata
      // Refer to tmImage.loadFromFiles() in the API to support files from a file picker
      // or files from your local hard drive
      // Note: the pose library adds "tmImage" object to your window (window.tmImage)
      model = await tmImage.load(modelURL, metadataURL);
      // maxPredictions = model.getTotalClasses();

      // Convenience function to setup a webcam
      const flip = true; // whether to flip the webcam
      webcam = new tmImage.Webcam(376.01, 282.01, flip); // width, height, flip
      await webcam.setup(); // request access to the webcam
      await webcam.play();
      window.requestAnimationFrame(loop);
    }

    async function loop() {
      webcam.update(); // update the webcam frame
      await predict();
      window.requestAnimationFrame(loop);
    }

    // run the webcam image through the image model
    async function predict() {
      setTimeout(async () => {
        // predict can take in an image, video or canvas html element
        const prediction = await model.predict(webcam.canvas);
        // for (let i = 0; i < maxPredictions; i++) {
        // const classPrediction =
        //   prediction[i].className + ": " + prediction[i].probability.toFixed(2);
        // labelContainer.childNodes[i].innerHTML = classPrediction;
        // console.log(
        //   "class prediction result",
        //   prediction[0].probability.toFixed(2),
        //   prediction[0].className
        // );
        setIsFrontFaced(prediction[0].probability.toFixed(2) == 1);
        // await webcam.stop();
      }, 1000);
      // }
    }
    //

    useEffect(() => {
      const webcamInstance = webcamRef.current;
      let streams: any;
      Promise.all([
        faceApi.nets.tinyFaceDetector.loadFromUri("/models/faceapi"),
        faceApi.nets.faceLandmark68Net.loadFromUri("/models/faceapi"),
        faceApi.nets.faceRecognitionNet.loadFromUri("/models/faceapi"),
        faceApi.nets.faceExpressionNet.loadFromUri("/models/faceapi"),
      ]).then(() => {
        init();
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ audio: false, video: true })
            .then(async function (stream) {
              streams = stream;
              //Display the video stream in the video object
              webcamInstance.srcObject = stream;
              //Play the video stream
              setTimeout(() => {
                addEvent();
              }, 1000);
              setVideoLoaded(true);
            })
            .catch(function (e) {
              console.log("error", e.name + ": " + e.message);
            });
        }
      });

      return () => {
        webcamInstance.removeEventListener("play", handlePlay);
        if (streams) streams.getTracks().forEach((track: any) => track.stop());
      };
    }, [webcamRef, handlePlay, addEvent]);

    useEffect(() => {
      if (videoLoaded) {
        setTimeout(() => {
          webcamRef.current.play();
        }, 2000);
      }
      return () => {};
    }, [videoLoaded, webcamRef]);

    const checkDistance =
      toNumber(detections.width) > minWidthFace &&
      toNumber(detections.width) < maxWidthFace;

    const checkFaceInCenter =
      toNumber(detections.x) > minOriginX &&
      toNumber(detections.x) < maxOriginX &&
      toNumber(detections.y) > minOriginY &&
      toNumber(detections.y) < maxOriginY;

    return (
      <>
        <h1 className="text-2xl mb-5 self-center flex">
          <svg
            onClick={() => {
              setIsClickImage(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 mr-2 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>{" "}
          Selected/Captured Image
        </h1>
        <h2 className="text-xl self-center">Keep your face in the circle</h2>
        <div className="mx-auto">
          <button
            className={`text-white self-center box-content my-3 mx-auto rounded-lg border p-1 px-4 items-center ${
              checkFaceInCenter
                ? "bg-green-700 cursor-pointer"
                : "bg-red-500 cursor-default"
            }`}
          >
            Face Position
            <span className="font-bold block text-sm">
              {checkFaceInCenter ? "Good" : " Not Good"}
            </span>
          </button>
          <button
            className={`text-white self-center box-content my-3 mx-auto rounded-lg border p-1 px-4 items-center ${
              isFrontFaced
                ? "bg-green-700 cursor-pointer"
                : "bg-red-500 cursor-default"
            }`}
          >
            Look Straight
            <span className="font-bold block text-sm">
              {isFrontFaced ? "Good" : " Not Good"}
            </span>
          </button>
        </div>
        <div
          id="cam-preview"
          className="relative rounded border-4 border-red-300	border-12 bg-black"
        >
          <video
            id="video-cam"
            ref={webcamRef}
            muted
            // autoPlay
            preload="none"
            style={{
              width: width,
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          />
          <div className="absolute self-center inset-0 w-1/2 h-3/2 mx-auto m-8 border-2 rounded-[50%]"></div>
          {modalLoading && (
            <div className="absolute flex inset-0 bg-black  bg-opacity-75">
              <div className="text-white text-lg mx-auto my-auto items-center flex">
                <Spinner aria-label="Default status example" />
                <p className="ml-2">Processing... Give us a minute!</p>
              </div>
            </div>
          )}
        </div>
        <div className="my-4 ">
          {!webcamRef.current && (
            <h1 className="text-center">
              <Spinner />
            </h1>
          )}
          {/* <h1 className="text-2xl">Instructions for better results :- </h1> */}
          {/* <Instructions
            icon={
              checkDistance ? <Tick color="green" /> : <Close color="red" />
            }
            text={
              <h2 className="text-xl ml-2">
                Keep proper distance from the webcam{" "}
                {detections.width && (
                  <>
                    <p>
                      {toNumber(detections.width) < minWidthFace &&
                        "(Try move closer to the webcam)"}{" "}
                      {toNumber(detections.width) > maxWidthFace &&
                        "(Try move away from the webcam)"}
                    </p>
                  </>
                )}
              </h2>
            }
          /> */}
          {/* <Instructions
            icon={
              checkFaceInCenter ? <Tick color="green" /> : <Close color="red" />
            }
            text={
              <h2 className="text-xl ml-2">Keep your face in the center</h2>
            }
          />
          <Instructions
            icon={isFrontFaced ? <Tick color="green" /> : <Close color="red" />}
            text={<h2 className="text-xl ml-2">Face should be straight</h2>}
          /> */}
          <div className="flex flex-col justify-center">
            {!modalLoading && (
              <button
                onClick={handleCapture}
                className={`text-white self-center box-content my-3 mx-auto rounded-lg border p-3 items-center ${
                  // checkFaceInCenter && isFrontFaced
                  true
                    ? "bg-green-700 cursor-pointer"
                    : "bg-gray-500 cursor-default"
                }`}
              >
                Capture
              </button>
            )}
            {checkFaceInCenter && isFrontFaced && (
              <h3 className="text-xl text-green-500 mt-1 text-center">
                Ready to capture
              </h3>
            )}{" "}
          </div>
        </div>
      </>
    );
  }
);

const CameraScan = React.forwardRef(
  ({ setIsClickImage, handleCapture }: CameraScanProps, ref: any) => {
    return (
      <WebCamRenderer
        setIsClickImage={setIsClickImage}
        webcamRef={ref}
        handleCapture={handleCapture}
      />
    );
  }
);

export default CameraScan;
