import React, { useState } from "react";
import { FeedbackModal } from "../../feedback";

const FeedbackForm = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <button
        className="fixed bottom-0 right-0 m-5 bg-white px-4 py-2 rounded-full  shadow-lg"
        onClick={() => setVisible(true)}
      >
        Feedback
      </button>
      <FeedbackModal visible={visible} handleVisible={setVisible} />
    </div>
  );
};

export default FeedbackForm;
