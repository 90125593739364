import { Button, Modal as FModal } from "flowbite-react";
import { useAlertContext } from "../../../context";

interface IProps {
  responseImage: string;
  setIsResponseModalOpen: (val: boolean) => void;
  isResponseModalOpen: boolean;
}

const FlowModal = (props: IProps) => {
  const { setAlertState } = useAlertContext();

  const closeModal = () => {
    props.setIsResponseModalOpen(false);
    closeAlert();
  };

  const closeAlert = () => {
    setTimeout(() => {
      setAlertState({
        status: "",
        text: "",
      });
    }, 3000);
  };

  return (
    <>
      <FModal show={props.isResponseModalOpen} onClose={closeModal}>
        <FModal.Header>Processed Image</FModal.Header>
        <FModal.Body>
          {props.responseImage && (
            <div className="max-w-sm mx-auto">
              <img src={props.responseImage} alt="processed" />
            </div>
          )}
        </FModal.Body>
        <FModal.Footer>
          {props.responseImage && (
            <a href={props.responseImage} download="processed_image.jpeg">
              <Button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 pr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                Download{" "}
              </Button>
            </a>
          )}{" "}
          <Button color="gray" onClick={closeModal}>
            Close
          </Button>
        </FModal.Footer>
      </FModal>
    </>
  );
};

export default FlowModal;
