import React from "react";
import { AppHeader } from "../organisms";

type AppLayoutProps = {
  children?: React.ReactNode;
};

export function AppLayout({ children }: AppLayoutProps) {
  return (
    <>
      <AppHeader />
      {children}
    </>
  );
}
