import { createContext, FunctionComponent, useContext, useState } from "react";

// ---------- ResponseContext ----------

type AlertStateSetter = (alertState: { status: string; text: string }) => void;

/** ResponseContext contains responseState and setResponseState */

const AlertContext = createContext<
  | {
      alertState: { status: string; text: string };
      setAlertState: AlertStateSetter;
    }
  | undefined
>(undefined);

type AlertContextProviderProps = {
  children?: React.ReactNode;
};

// ---------- ResponseContextProvider ----------
const AlertContextProvider: FunctionComponent<AlertContextProviderProps> = ({
  children,
}) => {
  const [alertState, setAlertState] = useState<{
    status: string;
    text: string;
  }>({ status: "", text: "" });
  const value: any = { alertState, setAlertState };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

// ---------- useLoaderContext ----------
function useAlertContext() {
  const alertContext = useContext(AlertContext);
  /* istanbul ignore next */
  if (alertContext === undefined) {
    throw new Error(
      "useResponseContext must be used within a ResponseContextProvider"
    );
  }
  return alertContext;
}

export { AlertContextProvider, useAlertContext };
