import COEImageURL from "../../../images/ai_data_coe_logo.png";

export function AppHeader() {
  return (
    <header className="bg-[#684669] flex p-2 justify-between items-center">
      <img className="w-12" src={COEImageURL} alt="AI&Data-COE Logo" />
      <h1 className="text-2xl text-white">Vision App</h1>
      <div className=""></div>
    </header>
  );
}
