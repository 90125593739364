import { useState } from "react";
import { Button, Modal as FModal, Rating, Spinner } from "flowbite-react";
import { environment } from "../../../config";
import { useResponseContext } from "../../../context/ResponseContext";
import { useAlertContext } from "../../../context";

const env = process.env.NODE_ENV || "development";

const getEnvProperties = () => {
  return environment.filter((envProp) => envProp.key === String(env));
};

const { FEEDBACK_FORM_API_URL } = getEnvProperties()[0];

type FeedbackModalProps = {
  visible: boolean;
  handleVisible: (flag: boolean) => void;
};

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  visible,
  handleVisible,
}: FeedbackModalProps) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { responseState } = useResponseContext();
  const [loading, setLoading] = useState(false);
  const { setAlertState } = useAlertContext();

  const closeModal = () => {
    handleVisible(false);
    hideAlert();
  };

  const hideAlert = () => {
    setTimeout(() => {
      setAlertState({
        status: "",
        text: "",
      });
    }, 3000);
  };

  const handleSubmit = () => {
    if (!comment) return;
    setLoading(true);
    const payload = JSON.stringify({
      request_id: responseState,
      rating,
      feedback: comment,
    });
    fetch(FEEDBACK_FORM_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "'*'",
      },
      body: payload,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          setAlertState({
            status: "success",
            text: "Feedback Successfully Submitted!",
          });
          handleVisible(false);
        } else {
          setAlertState({
            status: "failure",
            text: response,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setAlertState({
          status: "failure",
          text: "Error occurred while form submitting!",
        });
      })
      .finally(() => {
        setLoading(false);
        hideAlert();
      });
  };

  return (
    <>
      <FModal show={visible} onClose={closeModal}>
        <FModal.Header>Feedback Form</FModal.Header>
        <FModal.Body>
          <div className="mb-2">
            <h1 className="text-md ">Rating</h1>
            <Rating
              // onChange={(e) => setRating(e.target.value)}
              defaultValue={rating}
              onClick={(val) => console.log(val)}
            >
              {[1, 2, 3, 4, 5].map((value) => (
                <div onClick={() => setRating(value)}>
                  <Rating.Star key={value} filled={value <= rating} />
                </div>
              ))}
            </Rating>
          </div>
          <div>
            <h1 className="text-md ">Add Comment</h1>
            <textarea
              className="w-full h-24 md:w-34 lg:h-48"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </FModal.Body>
        <FModal.Footer>
          <Button onClick={loading ? undefined : handleSubmit}>
            {loading ? (
              <Spinner aria-label="Default status example" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button color="gray" onClick={closeModal}>
            Close
          </Button>
        </FModal.Footer>
      </FModal>
    </>
  );
};
