import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AlertContextProvider, LoaderContextProvider } from "./context";
import "./index.css";
import { ResponseContextProvider } from "./context/ResponseContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <LoaderContextProvider>
    <ResponseContextProvider>
      <AlertContextProvider>
        <App />
      </AlertContextProvider>
    </ResponseContextProvider>
  </LoaderContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
