import React from "react";

interface IProps {
  title: string;
  desc: string;
  image: any;
}

const FeatureCard = (props: IProps) => {
  return (
    <div className="bg-white p-4 m-2 rounded-md shadow text-center flex flex-col w-48  max-w-sm items-center">
      <img
        className="w-10 h-10 mr-1 self-center"
        src={props.image}
        alt="hair style"
      />
      <div>
        <h1 className="text-xl ">{props.title}</h1>
        <p className="text-sm">{props.desc}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
