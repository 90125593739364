import React, { useState } from "react";
import hs3 from "../../../../images/hs3.jpg";
import hs8 from "../../../../images/hs8.jpg";
import hs12 from "../../../../images/hs12.jpg";
import hs15 from "../../../../images/hs15.jpg";
import hs18 from "../../../../images/hs18.jpg";
import hs19 from "../../../../images/hs19.jpg";
import hs21 from "../../../../images/hs21.jpg";
import hs22 from "../../../../images/hs22.jpg";
import hs25 from "../../../../images/hs25.jpg";
import hs29 from "../../../../images/hs29.jpg";
import hs31 from "../../../../images/hs31.jpg";
import hs32 from "../../../../images/hs32.jpg";
import { environment } from "../../../../config";
import FlowModal from "../../modal/FlowModal";
import { useAlertContext, useLoaderContext } from "../../../../context";
import { useResponseContext } from "../../../../context/ResponseContext";
import FeedbackForm from "../feedbackform/FeedbackForm";
import HairStyleComponent from "./HairStyleComponent";
import { isArray, isString } from "lodash";

const hairStylesImages = [
  { key: "hs3", url: hs3, name: "Red Wavy Lace" },
  { key: "hs8", url: hs8, name: "Edgy Lob" },
  { key: "hs12", url: hs12, name: "The Bixie" },
  { key: "hs15", url: hs15, name: "Modern Full Curls" },
  { key: "hs18", url: hs18, name: "Curly Udpo" },
  { key: "hs19", url: hs19, name: "Wavy Blonde with Side Swept Bangs" },
  { key: "hs21", url: hs21, name: "Long Straight Auburn Brunette" },
  { key: "hs22", url: hs22, name: "Long Wavy" },
  { key: "hs25", url: hs25, name: "Medium Wavy" },
  { key: "hs29", url: hs29, name: "Soft Layered Bob" },
  { key: "hs31", url: hs31, name: "Medium Length Wavy Curly" },
  { key: "hs32", url: hs32, name: "Short Lob" },
];

const env = process.env.NODE_ENV || "development";

const getEnvProperties = () => {
  return environment.filter((envProp) => envProp.key === String(env));
};

const { HAIR_STYLE_TRANSFORMATION_API_URL } = getEnvProperties()[0];

interface IProps {
  image: any;
}

const HairStyles = (props: IProps) => {
  const [hairStyleId, setHairStyleId] = useState("");
  const [hairTransformationResponse, setHairTransformationResponse] =
    useState("");
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const { setLoadingState } = useLoaderContext();
  const { setResponseState } = useResponseContext();
  const { setAlertState } = useAlertContext();

  const handleHairStyleClick = (id: string) => {
    setAlertState({
      status: "",
      text: "",
    });
    setHairTransformationResponse("");
    setHairStyleId(id);
    setLoadingState(true);
    const hairStyleTransformationURL = HAIR_STYLE_TRANSFORMATION_API_URL;
    const sid = id;
    const payload = JSON.stringify({
      sid: sid,
      pre_crop: 1,
      size_val: 1,
      forehead_val: 1,
      shoulder_val: 1,
      alignment_val: 1,
      alignment: 1,
      bg_method: "mp",
      bg_cvz_thresh: 0.5,
      n_seg: 1,
      post_crop: 1,
      post_vals: 1,
      debug: 1,
      seg_method: "bisenet",
      seg_input_size: 512,
      base64str: props.image.replace("data:", "").replace(/^.+,/, ""),
    });
    fetch(hairStyleTransformationURL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) => {
        if (isArray(response)) {
          setAlertState({
            status: "success",
            text: "Image Successfully Processed!",
          });
          let responseSrc = "data:image/jpeg;base64,".concat(response[0]);
          setHairTransformationResponse(responseSrc);
          setIsResponseModalOpen(true);
          setResponseState(response[1]);
        } else {
          setAlertState({
            status: "failure",
            text: isString(response)
              ? response
              : "Processing Failed try again!",
          });
        }
      })
      .catch((err) => {
        setAlertState({
          status: "failure",
          text: "Error occurred while processing image!",
        });
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  return (
    <div>
      {hairTransformationResponse && <FeedbackForm />}
      <FlowModal
        isResponseModalOpen={isResponseModalOpen}
        setIsResponseModalOpen={setIsResponseModalOpen}
        responseImage={hairTransformationResponse}
      />
      <h1 className="text-xl pb-2">Select Hair Styles</h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
        {hairStylesImages.map((hairStyleImage, idx) => {
          return (
            <HairStyleComponent
              key={idx}
              data={hairStyleImage}
              onClick={handleHairStyleClick}
              hairStyleId={hairStyleId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HairStyles;
