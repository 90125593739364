import { createRef, useCallback, useRef, useState } from "react";
import sample1 from "../../../../images/pose1.jpg";
import sample3 from "../../../../images/pose3.png";
import sample4 from "../../../../images/pose4.png";
import sample5 from "../../../../images/pose5.png";
import makeup from "../../../../images/makeupbg.jpg";
import { encodeFileToBase64String } from "../../../../utils/utils";
import CameraScan from "../../CameraScan";

type ImageSelectionProps = {
  image: any;
  setImage: (flag: string) => void;
  isClickImage: boolean;
  setIsClickImage: (value: boolean) => void;
};

export const ImageSelectionOption = ({
  image,
  setImage,
  isClickImage,
  setIsClickImage,
}: ImageSelectionProps) => {
  const webcamRef = createRef();
  const inputFileRef = useRef<any>(null);

  const selectExampleImage = (selectedImg: any) => {
    const image: HTMLImageElement = document.getElementById(selectedImg) as any;
    // Get the remote image as a Blob with the fetch API
    fetch(image?.src)
      .then((res) => res.blob())
      .then((blob) => {
        // Read the Blob as DataURL using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader?.result as any);
        };
        reader.readAsDataURL(blob);
      });
  };

  const capture = useCallback(() => {
    if (webcamRef && webcamRef.current) {
      var canvas: any = document.createElement("canvas");

      canvas.width = 370;
      canvas.height = 278;
      canvas.getContext("2d").drawImage(webcamRef.current, 0, 0, 370, 278);
      setImage(canvas.toDataURL("image/png"));
    }
  }, [webcamRef, setImage]);

  const handleCapture = () => {
    capture();
  };

  const uploadImage = (e: any) => {
    let base64 = encodeFileToBase64String(e.target.files[0]);
    base64
      .then((base64Str: any) => {
        setImage(base64Str);
      })
      .catch((err) => {
        console.error(err);
      });
    return false;
  };

  if (isClickImage) {
    return (
      <div className="flex relative flex-col content-center max-w-sm my-5">
        <CameraScan
          setIsClickImage={setIsClickImage}
          ref={webcamRef}
          handleCapture={handleCapture}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${makeup})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
      }}
      className="relative  flex flex-col gap-12 md:gap-14 max-w-sm items-center  text-white p-12 rounded-lg "
    >
      <>
        <h1 className="text-2xl">Let's get started!</h1>

        <div>
          <h1 className="text-xl">
            Click below images to try out with sample images{" "}
          </h1>
          <div className="flex justify-around gap-2 mt-2 mb-2">
            <img
              id="sample1"
              className="rounded overflow-hidden h-[80px] cursor-pointer"
              src={sample1}
              width={80}
              onClick={() => selectExampleImage("sample1")}
              alt="sample-1"
            />
            <img
              id="sample3"
              className="rounded h-[80px] cursor-pointer"
              src={sample3}
              width={80}
              onClick={() => selectExampleImage("sample3")}
              alt="sample-2"
            />
            <img
              id="sample4"
              className="rounded h-[80px] cursor-pointer"
              src={sample4}
              width={80}
              onClick={() => selectExampleImage("sample4")}
              alt="sample-3"
            />{" "}
            <img
              id="sample5"
              className="rounded h-[80px] cursor-pointer"
              src={sample5}
              width={80}
              onClick={() => selectExampleImage("sample5")}
              alt="sample-4"
            />
          </div>
          <h1 className="text-sm font-bold">
            For better results, please align your face and tie up your hair
            similiar to the above example images
          </h1>
        </div>
        <input
          ref={inputFileRef}
          onChange={uploadImage}
          type="file"
          style={{ display: "none" }}
          // multiple={false}
        />
        <button
          className="flex align-items-center rounded-lg  p-3 items-center bg-[#684669] "
          onClick={() => inputFileRef.current.click()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
            />
          </svg>
          <p className="ml-2">Upload Image</p>
        </button>
        <button
          className="flex align-items-center rounded-lg bg-[#684669] p-3 items-center"
          onClick={() => setIsClickImage(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
            />
          </svg>{" "}
          <p className="ml-2">Capture Image</p>
        </button>
      </>
    </div>
  );
};
