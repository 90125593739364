import { Home, Loader } from "./components";
import { useLoaderContext } from "./context";

export default function App() {
  const { isLoadingState } = useLoaderContext();
  return (
    <Loader isLoading={isLoadingState}>
      <Home />
    </Loader>
  );
}
