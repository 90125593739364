import { Spinner } from "flowbite-react";
import React from "react";

type LoaderProps = {
  isLoading: boolean;
  children?: React.ReactNode;
};

export const Loader: React.FC<LoaderProps> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed right-0 top-0 bottom-0 left-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center text-white">
          <Spinner aria-label="Default status example" />
          {/* <h1 className="text-2xl pt-2">Loading...</h1> */}
          <p>We are working on it, give us a minute!</p>
        </div>
      )}
      {children}
    </>
  );
};
