import React from "react";
import FeatureCard from "../../../molecules/card/FeatureCard";
import hairstyle from "../../../../images/hairstyle.png";
import haircolor from "../../../../images/haircolor.png";
import lipcolor from "../../../../images/lipcolor.png";

const ProductInfo = () => {
  return (
    <div className="m-2">
      <h1 className="text-2xl text-center">PS Virtual HairStyler</h1>
      <div className="mt-2  p-2 rounded-md ">
        <h3 className="text-lg tracking-wide p-2">
          Global cosmetic products market is expected to reach approximately USD
          863 billion in 2024.
        </h3>
        <hr />
        <h3 className="text-md p-2 tracking-wide">
          Publicis Sapient offers AI based tool for tying out different hair
          styles on the go. It is powered by our internal AI/ML accelerator
          Bodhi Vision for scalability.
        </h3>
      </div>
      <div className=" mb-4">
        <h1 className="text-2xl text-center">Features</h1>
        <div className="flex flex-col items-center md:flex-row justify-center">
          <FeatureCard
            title={"Hair Style"}
            desc={"Apply style from an array of model hair looks."}
            image={hairstyle}
          />
          <FeatureCard
            title={"Hair Color Shade"}
            desc={"Try out different hair color on the go"}
            image={haircolor}
          />
          <FeatureCard
            title={"Lip Shades"}
            desc={"Try out different lip color shades"}
            image={lipcolor}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
