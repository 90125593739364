const environment = [
  {
    key: "development",
    HAIR_STYLE_TRANSFORMATION_API_URL:
      "https://2v66hmw8y2.execute-api.eu-west-1.amazonaws.com/Stage/hairstyle",
    HAIR_COLOR_TRANSFORMATION_API_URL:
      "https://2v66hmw8y2.execute-api.eu-west-1.amazonaws.com/Stage/haircolor",
    FEEDBACK_FORM_API_URL:
      "https://rbfp0nthg7.execute-api.eu-west-1.amazonaws.com/dev/feedback",
  },
  {
    key: "production",
    HAIR_STYLE_TRANSFORMATION_API_URL:
      "https://2v66hmw8y2.execute-api.eu-west-1.amazonaws.com/Stage/hairstyle",
    HAIR_COLOR_TRANSFORMATION_API_URL:
      "https://2v66hmw8y2.execute-api.eu-west-1.amazonaws.com/Stage/haircolor",
    FEEDBACK_FORM_API_URL:
      "https://rbfp0nthg7.execute-api.eu-west-1.amazonaws.com/dev/feedback",
  },
];
export { environment };

// hair style prod
// "https://bodhivision.platform-aw.psbodhi.ai/ApplyHairStyleTransformation",
// hair color
// "https://bodhivision.platform-aw.psbodhi.ai/ApplyHairColorTransformation",
