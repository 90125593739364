import { createContext, FunctionComponent, useContext, useState } from "react";

// ---------- ResponseContext ----------

type ResponseStateSetter = (responseState: string) => void;

/** ResponseContext contains responseState and setResponseState */

const ResponseContext = createContext<
  { responseState: string; setResponseState: ResponseStateSetter } | undefined
>(undefined);

type ResponseContextProviderProps = {
  children?: React.ReactNode;
};

// ---------- ResponseContextProvider ----------
const ResponseContextProvider: FunctionComponent<
  ResponseContextProviderProps
> = ({ children }) => {
  const [responseState, setResponseState] = useState<string>("");
  const value = { responseState, setResponseState };

  return (
    <ResponseContext.Provider value={value}>
      {children}
    </ResponseContext.Provider>
  );
};

// ---------- useLoaderContext ----------
function useResponseContext() {
  const loaderContext = useContext(ResponseContext);
  /* istanbul ignore next */
  if (loaderContext === undefined) {
    throw new Error(
      "useResponseContext must be used within a ResponseContextProvider"
    );
  }
  return loaderContext;
}

export { ResponseContextProvider, useResponseContext };
